.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
*{
    font-family: 'blinker', sans-serif !important;
    position: relative;
  }
h1,h2, .heading-font{font-family: 'Big Shoulders Display', sans-serif !important;}
.color-primary{color: #005400;}
.color-primary-dark{color: #000e00 !important;}
.background-primary{background: #005400 !important;}
.background-primary-dark{background: #000e00 !important;}
a{
  text-decoration: none !important;
}
.giant-text{
  font-size: 600%;
  font-weight:800 !important;
}
.big-text{
  font-size: 600%;
  font-weight:800 !important;
}
.larger-text{
  font-size: 400%;
}
.small-text{
  font-size: 80%;
}
.clickable{
  cursor: pointer;
}
.hover-color{
  cursor: pointer;
}
.hover-color:hover{
  background-color: whitesmoke !important;
}
/*** Loader Styles ***/
.loading {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
  animation: pulse 2s infinite ease-out;
  background: #d3d3d3;
  display: flex;
  align-items: center;
  justify-content: center;
}
@keyframes pulse {
  0% {
    opacity: 1;
  }
  50%{
    opacity: .1;
  }
  100% {
    opacity: 1;
  }
}


/*** End Loader Styles ***/

/*** Slick Slider ***/
.slick-dots{
  bottom: 20px;
}
.slick-dots li button::before{
  color: whitesmoke;
  opacity: .4;
  font-size: 10px;
}
.slick-dots li.slick-active button::before{
  opacity: 1;
  color: whitesmoke;
}
.slick-arrow.slick-prev::before{
  opacity: .9;
  font-size: 24px;
}
.slick-arrow.slick-next::before{
  opacity: .9;
  font-size: 24px;
}

/*** Fonts ****/
/*h1, h2, h3, h4, h5, h6{
  font-family: 'Poppins', sans-serif !important;
}
p, li, a{
  font-family: 'Poppins', sans-serif;
}*/

.banner-image{
  /*width: 100vw !important;*/
  height: 100% !important;
}
.lg-100-vh-then-grow{
  height: 100vh;
}
@media screen and (max-width: 1200px){
  
  .giant-text{
    font-size: 380% !important;
  }
  .big-text{
    font-size: 380% !important;
  }
}
@media screen and (max-width: 992px) {
  
}
@media screen and (max-width: 768px){
  .lg-only{display: none;}
  .adaptive-banner-container{
    border-right: none !important;
    border-top: none !important;
    height: 420px !important;
  }
  .height-fix{
    height: 420px !important;
  }
  .banner-image{
    width: auto !important;
    max-height: 420px !important;
  }
  .larger-text{
    font-size: 220% !important;
  }
  .md-no-border-bottom{
    border-bottom: none !important;
  }
  .md-no-border-top{
    border-top: none !important;
  }
  .md-no-border{
    border: none !important;
  }
  .lg-100-vh-then-grow{
    height: auto !important;
  }
}
@media screen and (max-width: 576px){
  #block-nav-inner{
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
  }
  #block-nav-brand{
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
  #block-nav-ul{
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100% !important;
    flex-direction: row !important;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
